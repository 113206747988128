import React, { useCallback, useMemo, useState } from 'react';
import { Button, Card, CardBody, CardTitle, Col, Modal, ModalBody, Row } from 'reactstrap';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useMediaQuery } from 'react-responsive';
import { nextAsset } from './nextAsset';
import { DeviceType } from './types';
import { useDevice } from './hooks/useDevice';
import { useIsClient } from './hooks/useIsClient';

interface AvailableVersionButton {
  to: string;
  onClick?: (e: React.MouseEvent) => void;
  name: string;
  image: string;
  isMobile: boolean;
  alt: string;
}

export const {
  desktopOutlineAlt,
  phoneOutlineAlt,
  downloadMacOS,
  downloadWindows,
  downloadLinux,
  downloadIOS,
  downloadAndroid
} = defineMessages({
  desktopOutlineAlt: {
    id: 'web.download_buttons.desktop_outline_alt',
    defaultMessage: 'Notebook outline'
  },
  phoneOutlineAlt: {
    id: 'web.download_buttons.phone_outline_alt',
    defaultMessage: 'Smartphone outline'
  },
  downloadMacOS: {
    id: 'web.download_buttons.download.macOS',
    defaultMessage: 'Download macOS'
  },
  downloadWindows: {
    id: 'web.download_buttons.download.windows',
    defaultMessage: 'Download Windows'
  },
  downloadLinux: {
    id: 'web.download_buttons.download.linux',
    defaultMessage: 'Download Linux'
  },
  downloadAndroid: {
    id: 'web.download_buttons.download.android',
    defaultMessage: 'Download FreeYourMusic for Android'
  },
  downloadIOS: {
    id: 'web.download_buttons.download.ios',
    defaultMessage: 'Download FreeYourMusic for iOS'
  }
});

export const DownloadButtons: React.FC<{
  forUpselling?: boolean;
}> = React.memo(({ forUpselling }) => {
  const device = useDevice();
  const intl = useIntl();
  const { locale } = useRouter();
  const [macOSPickerShown, setMacOSPickerShown] = useState<boolean>(false);

  const onMacOSClicked = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setMacOSPickerShown(true);
  }, []);
  const macOSModalToggle = useCallback(() => {
    setMacOSPickerShown(false);
  }, []);

  const isClient = useIsClient();
  const isBigScreen = useMediaQuery({ query: '(min-width: 1200px)' });
  const androidVersion = useMemo(
    () => ({
      to: '/download?platform=android',
      name: 'Android',
      image: nextAsset(`images/home/top/download-googleplay-${locale}.svg`),
      isMobile: true,
      alt: intl.formatMessage(downloadAndroid)
    }),
    [intl, locale]
  );
  const iOSVersion = useMemo(
    () => ({
      to: '/download?platform=ios',
      name: 'iOS',
      image: nextAsset(`images/home/top/download-appstore-${locale}-light.svg`),
      isMobile: true,
      alt: intl.formatMessage(downloadIOS)
    }),
    [intl, locale]
  );
  const macOSVersion: AvailableVersionButton = useMemo(
    () => ({
      to: '/download?platform=macos',
      onClick: onMacOSClicked,
      name: 'macOS',
      image: 'macos-download',
      isMobile: false,
      alt: intl.formatMessage(downloadMacOS)
    }),
    [intl, onMacOSClicked]
  );
  const windowsVersion = useMemo(
    () => ({
      to: '/download?platform=windows',
      name: 'Windows',
      image: 'windows-download',
      isMobile: false,
      alt: intl.formatMessage(downloadWindows)
    }),
    [intl]
  );
  const linuxVersion = useMemo(
    () => ({
      to: '/download?platform=linux',
      name: 'Linux',
      image: 'linux-download',
      isMobile: false,
      alt: intl.formatMessage(downloadLinux)
    }),
    [intl]
  );

  const showToUserVersion: AvailableVersionButton = useMemo(() => {
    switch (device) {
      case DeviceType.Android:
        return androidVersion;
      case DeviceType.iOS:
        return iOSVersion;
      case DeviceType.Windows:
        return windowsVersion;
      case DeviceType.OSX:
        return macOSVersion;
      case DeviceType.Linux:
        return linuxVersion;
      default:
        return windowsVersion;
    }
  }, [androidVersion, device, iOSVersion, linuxVersion, macOSVersion, windowsVersion]);
  const availableVersions: AvailableVersionButton[] = useMemo(() => {
    const allAvailableVersions = [androidVersion, iOSVersion, macOSVersion, windowsVersion, linuxVersion];

    return allAvailableVersions.filter((v) => v.name !== showToUserVersion?.name);
  }, [androidVersion, iOSVersion, linuxVersion, macOSVersion, showToUserVersion?.name, windowsVersion]);

  return (
    <>
      <Row
        className={classNames('downloadButtons pt-4 justify-content-center d-none d-xl-flex', {
          forUpselling
        })}
      >
        <Col>
          <Row>
            <Col className={classNames('text-end px-0', { 'flex-grow-0': forUpselling })}>
              {isClient && isBigScreen && (
                <img
                  src={
                    forUpselling
                      ? nextAsset('images/home/top/desktop-outline-black.svg')
                      : nextAsset('images/home/top/desktop-outline.svg')
                  }
                  className="img-fluid"
                  alt={intl.formatMessage(desktopOutlineAlt)}
                />
              )}
            </Col>
            <Col className="text-start">
              <Button color="light" className="download-button macos-download mb-3 me-2" onClick={onMacOSClicked}>
                {intl.formatMessage(downloadMacOS)}
              </Button>{' '}
              <Link prefetch={false} href="/download?platform=windows" passHref>
                <Button color="light" className="download-button windows-download mb-3 me-2">
                  {intl.formatMessage(downloadWindows)}
                </Button>
              </Link>
              <br />
              <Link prefetch={false} href="/download?platform=linux" passHref>
                <Button color="light" className="download-button linux-download mb-3 me-2">
                  {intl.formatMessage(downloadLinux)}
                </Button>
              </Link>{' '}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col className={classNames('text-end px-0', { 'flex-grow-0': forUpselling })}>
              {isClient && isBigScreen && (
                <img
                  className="img-fluid"
                  src={
                    forUpselling
                      ? nextAsset('images/home/top/phone-outline-black.svg')
                      : nextAsset('images/home/top/phone-outline.svg')
                  }
                  alt={intl.formatMessage(phoneOutlineAlt)}
                />
              )}
            </Col>
            <Col className="text-start">
              <Link prefetch={false} href="/download?platform=ios">
                <img
                  src={nextAsset(`images/home/top/download-appstore-${locale}-light.svg`)}
                  className="img-fluid download-native-store-image"
                  alt={intl.formatMessage(downloadIOS)}
                />
              </Link>{' '}
              <Link prefetch={false} href="/download?platform=android">
                <img
                  src={nextAsset(`images/home/top/download-googleplay-${locale}.svg`)}
                  className="img-fluid download-native-store-image"
                  alt={intl.formatMessage(downloadAndroid)}
                />
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className={classNames('downloadButtons pt-4 justify-content-center d-xl-none text-center', { forUpselling })}
      >
        <Col>
          {showToUserVersion.isMobile ? (
            <Link prefetch={false} href={showToUserVersion.to} onClick={showToUserVersion.onClick}>
              <img
                src={showToUserVersion.image}
                className="img-fluid download-native-store-image"
                alt={showToUserVersion.alt}
              />
            </Link>
          ) : (
            <Link prefetch={false} href={showToUserVersion.to} passHref>
              <Button
                color="light"
                className={classNames('download-button mb-3 me-2', showToUserVersion.image)}
                onClick={showToUserVersion.onClick}
              >
                {showToUserVersion.alt}
              </Button>
            </Link>
          )}
          <p className="pt-5 opacity-50">
            <FormattedMessage id="web.download_buttons.also_on" defaultMessage="We're also available on" />
          </p>
          <p className={classNames('fw-bold text-center d-flex px-3 flex-wrap alsoOn justify-content-center')}>
            {availableVersions.map((v) => (
              <Link prefetch={false} href={v.to} onClick={v.onClick} key={v.to}>
                {v.name}
              </Link>
            ))}
          </p>
        </Col>
      </Row>
      <Modal isOpen={macOSPickerShown} toggle={macOSModalToggle} centered color="light">
        <ModalBody className="px-4">
          <h3 className="text-center pt-4 pb-3">
            {intl.formatMessage({
              defaultMessage: 'Select the version that’s right for your Mac',
              id: 'web.macos-version-picker.title'
            })}
          </h3>
          <Row className="py-3">
            <Col className="text-center">
              <Link prefetch={false} href="/download?platform=macos" passHref>
                <Button block color="primary" size="lg">
                  {intl.formatMessage({
                    defaultMessage: 'Mac with Intel chip',
                    id: 'web.macos-version-picker.button.intel'
                  })}
                </Button>
              </Link>
              <small>
                {intl.formatMessage({
                  defaultMessage: 'MOST COMMON',
                  id: 'web.macos-version-picker.most-common'
                })}
              </small>
            </Col>
            <Col>
              <Link prefetch={false} href="/download?platform=macosarm" passHref>
                <Button block color="light" size="lg">
                  {intl.formatMessage({
                    defaultMessage: 'Mac with Apple chip',
                    id: 'web.macos-version-picker.button.apple'
                  })}
                </Button>
              </Link>
            </Col>
          </Row>
          <Card className="my-5" color="light">
            <CardBody>
              <CardTitle tag="h5">
                {intl.formatMessage({
                  defaultMessage: 'Check which chip your Mac has',
                  id: 'web.macos-version-picker.check.title'
                })}
              </CardTitle>
              <ol className="px-3">
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'At the top left, open the Apple menu.',
                    id: 'web.macos-version-picker.check.step1'
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'Select About This Mac.',
                    id: 'web.macos-version-picker.check.step2'
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'In the “Overview” tab, look for “Processor” or “Chip”.',
                    id: 'web.macos-version-picker.check.step3'
                  })}
                </li>
                <li>
                  {intl.formatMessage({
                    defaultMessage: 'Check if it says “Intel” or “Apple”.',
                    id: 'web.macos-version-picker.check.step4'
                  })}
                </li>
              </ol>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
});
