import { useEffect, useMemo, useState } from 'react';
import { isAndroid, isIOS, osName } from 'react-device-detect';
import { DeviceType } from '../types';

export function useDevice() {
  const [device, setDevice] = useState<DeviceType>(DeviceType.iOS);

  useEffect(() => {
    if (isAndroid) {
      setDevice(DeviceType.Android);
    } else if (isIOS) {
      setDevice(DeviceType.iOS);
    } else if (osName === 'Windows') {
      setDevice(DeviceType.Windows);
    } else if (osName === 'Mac OS') {
      const isTouchEnabled = 'ontouchend' in document;
      if (isTouchEnabled) {
        setDevice(DeviceType.iOS);
      } else {
        setDevice(DeviceType.OSX);
      }
    } else {
      setDevice(DeviceType.Linux);
    }
  }, [device]);
  return device;
}

export function useIsDesktop() {
  const device = useDevice();
  return useMemo(() => [DeviceType.OSX, DeviceType.Windows, DeviceType.Linux].includes(device), [device]);
}
